import type { SetupWorkerApi } from "msw";
import { lazy, Fragment, Suspense } from "react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { PageLoader, StordApp } from "@stordco/fe-components";

import { initIcons } from "./icons";
import "./styles/global.css";

const App = lazy(() => import("./components/App"));

const isDev = process.env.NODE_ENV === "development";

initIcons();

const prepareWorker = async () => {
  if (isDev) {
    const { MSWToolbar } = await import("@stordco/msw-toolbar");
    const { setupWorker } = await import("msw");
    const {
      bffOrdersIndexMockHandler,
      bffOrderDetailsMockHandler,
      facilityActivityDetailsMockHandler,
      facilityActivityIndexMockHandler,
      networkResponsesV2MockHandler,
      bffQualifierTrackingMockHandler,
      inventoryResponseV2MockHandler,
      bffUserActivateMockHandler,
      territoriesMockHandler,
      bffUsersMockHandler,
      bffDeactivateUserMockHandler,
      searchItemHandler,
      getChannelsHandler,
      bffResendInvitationMockHandler,
      // documentsHandler,
      inviteUsersMockHandler,
      bffCancelUserMockHandler,
      bffResetPasswordMockHandler,
      bffUpdateUserProfileMockHandler,
      bffReactivateUserMockHandler,
      getTradeConnectionsHandler,
      createOrderHandler,
      createTransferOrderHandler,
      facilityAvailabilityHandler,
      facilityAvailabilityHandlerV2,
      signedUrlsMockHandler,
      bffRolesMockHandler,
      bffInviteUsersV2MockHandler,
      bffUpdateUserRoleMockHandler,
      productService,
      getTradeConnectionHandler,
      shipmentConfirmationCancellationHandler,
      editOrderLines400Handler,
      timezones,
    } = await import("./handlers");

    const worker = setupWorker(
      ...[
        bffOrdersIndexMockHandler,
        bffOrderDetailsMockHandler,
        facilityActivityDetailsMockHandler,
        facilityActivityIndexMockHandler,
        networkResponsesV2MockHandler,
        bffQualifierTrackingMockHandler,
        inventoryResponseV2MockHandler,
        bffUserActivateMockHandler,
        territoriesMockHandler,
        bffUsersMockHandler,
        bffDeactivateUserMockHandler,
        searchItemHandler,
        getChannelsHandler,
        inviteUsersMockHandler,
        bffCancelUserMockHandler,
        bffResendInvitationMockHandler,
        bffResetPasswordMockHandler,
        bffUpdateUserProfileMockHandler,
        bffReactivateUserMockHandler,
        signedUrlsMockHandler,
        getTradeConnectionsHandler,
        createOrderHandler,
        createTransferOrderHandler,
        facilityAvailabilityHandler,
        facilityAvailabilityHandlerV2,
        bffRolesMockHandler,
        bffInviteUsersV2MockHandler,
        bffUpdateUserRoleMockHandler,
        productService,
        getTradeConnectionHandler,
        shipmentConfirmationCancellationHandler,
        editOrderLines400Handler,
        timezones,
      ].flat()
    );

    return { worker, MSWToolbar };
  }

  return { worker: null, MSWToolbar: Fragment };
};

async function renderApp({
  worker,
  MSWToolbar,
}: {
  worker: SetupWorkerApi;
  MSWToolbar: any;
}) {
  ReactDOM.render(
    <Suspense fallback={<PageLoader />}>
      <MSWToolbar
        worker={worker}
        isEnabled={isDev}
        apiUrl="/api"
        prefix="shipper"
      >
        <StordApp version={__APP_VERSION__}>
          <App />
          <ReactQueryDevtools position={"bottom-right"} />
        </StordApp>
      </MSWToolbar>
    </Suspense>,
    document.getElementById("root")
  );
}

prepareWorker().then(({ worker, MSWToolbar }) => {
  renderApp({ worker, MSWToolbar });
});
