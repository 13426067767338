/* Available fontAwesome icon sets to this project are:
    "@fortawesome/free-solid-svg-icons"
    "@fortawesome/pro-duotone-svg-icons"
    "@fortawesome/pro-light-svg-icons"
    "@fortawesome/pro-regular-svg-icons"
    "@fortawesome/pro-solid-svg-icons"
*/

import { faAngleDown } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faAngleDown as faAngleDownLight } from "@fortawesome/pro-light-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { faAnglesUp as faAnglesUpRegular } from "@fortawesome/pro-regular-svg-icons/faAnglesUp";
import { faArrowDownToLine } from "@fortawesome/pro-light-svg-icons/faArrowDownToLine";
import { faArrowsRotate } from "@fortawesome/pro-light-svg-icons/faArrowsRotate";
import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown";
import { faArrowUpFromLine } from "@fortawesome/pro-light-svg-icons/faArrowUpFromLine";
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons/faArrowUpRight";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare";
import { faAsterisk } from "@fortawesome/pro-light-svg-icons/faAsterisk";
import { faBan } from "@fortawesome/pro-light-svg-icons/faBan";
import { faBolt as faBoltRegular } from "@fortawesome/pro-regular-svg-icons/faBolt";
import { faBolt as faBoltSolid } from "@fortawesome/pro-solid-svg-icons/faBolt";
import { faBoltSlash } from "@fortawesome/pro-light-svg-icons/faBoltSlash";
import { faBooks } from "@fortawesome/pro-light-svg-icons/faBooks";
import { faBoxCheck } from "@fortawesome/pro-light-svg-icons/faBoxCheck";
import { faBoxesStacked } from "@fortawesome/pro-light-svg-icons/faBoxesStacked";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons/faBuilding";
import { faBuilding as faBuildingLight } from "@fortawesome/pro-light-svg-icons/faBuilding";
import { faBuildingCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faBuildingCircleExclamation";
import { faBuildings } from "@fortawesome/pro-light-svg-icons/faBuildings";
import { faBullseyePointer } from "@fortawesome/pro-light-svg-icons/faBullseyePointer";
import { faCalendarCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faCalendarCircleExclamation";
import { faCalendarXmark as faCalendarXmarkRegular } from "@fortawesome/pro-regular-svg-icons/faCalendarXmark";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCartFlatbedEmpty } from "@fortawesome/pro-light-svg-icons/faCartFlatbedEmpty";
import { faChartNetwork } from "@fortawesome/pro-light-svg-icons/faChartNetwork";
import { faChartPie } from "@fortawesome/pro-light-svg-icons/faChartPie";
import { faChartPieSimple } from "@fortawesome/pro-light-svg-icons/faChartPieSimple";
import { faCheck as faCheckRegular } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faCircleInfo as faLightCircleInfo } from "@fortawesome/pro-light-svg-icons/faCircleInfo";
import { faCirclePlus } from "@fortawesome/pro-light-svg-icons/faCirclePlus";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons/faCircleX";
import { faCircleX as faCircleXRegular } from "@fortawesome/pro-regular-svg-icons/faCircleX";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClipboardList } from "@fortawesome/pro-light-svg-icons/faClipboardList";
import { faClock as faClockRegular } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faClockRotateLeft } from "@fortawesome/pro-light-svg-icons/faClockRotateLeft";
import { faCloudArrowUp } from "@fortawesome/pro-light-svg-icons/faCloudArrowUp";
import { faColon } from "@fortawesome/pro-solid-svg-icons/faColon";
import { faDiagramVenn } from "@fortawesome/pro-light-svg-icons/faDiagramVenn";
import { faDiamondExclamation } from "@fortawesome/pro-regular-svg-icons/faDiamondExclamation";
import { faDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faEllipsisVertical as faEllipsisVerticalRegular } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import { faEllipsisVertical as faEllipsisVerticalSolid } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope";
import { faEquals as faEqualsRegular } from "@fortawesome/pro-regular-svg-icons/faEquals";
import { faFileCircleXmark } from "@fortawesome/pro-light-svg-icons/faFileCircleXmark";
import { faFileImport } from "@fortawesome/pro-light-svg-icons/faFileImport";
import { faFolderGear } from "@fortawesome/pro-light-svg-icons/faFolderGear";
import { faGaugeCirclePlus } from "@fortawesome/pro-regular-svg-icons/faGaugeCirclePlus";
import { faGear } from "@fortawesome/pro-light-svg-icons/faGear";
import { faGears as faGearsSolid } from "@fortawesome/pro-solid-svg-icons/faGears";
import { faHandHoldingBox } from "@fortawesome/pro-light-svg-icons/faHandHoldingBox";
import { faHouse } from "@fortawesome/pro-light-svg-icons/faHouse";
import { faLinkHorizontal } from "@fortawesome/pro-light-svg-icons/faLinkHorizontal";
import { faListTree } from "@fortawesome/pro-light-svg-icons/faListTree";
import { faLocationCheck } from "@fortawesome/pro-light-svg-icons/faLocationCheck";
import { faLocationPinLock } from "@fortawesome/pro-light-svg-icons/faLocationPinLock";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faLock as faSolidLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlass";
import { faMagnifyingGlassChart } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlassChart";
import { faMessagesQuestion } from "@fortawesome/pro-light-svg-icons/faMessagesQuestion";
import { faParachuteBox } from "@fortawesome/pro-light-svg-icons/faParachuteBox";
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen";
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons/faPenToSquare";
import { faPersonDollyEmpty } from "@fortawesome/pro-light-svg-icons/faPersonDollyEmpty";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { faPlus as faPlusLight } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faPlus as faPlusRegular } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faPuzzle } from "@fortawesome/pro-light-svg-icons/faPuzzle";
import { faPuzzlePiece } from "@fortawesome/pro-light-svg-icons/faPuzzlePiece";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faReceipt } from "@fortawesome/pro-light-svg-icons/faReceipt";
import { faRefresh } from "@fortawesome/pro-light-svg-icons/faRefresh";
import { faRoute } from "@fortawesome/pro-light-svg-icons/faRoute";
import { faRulerTriangle } from "@fortawesome/pro-light-svg-icons/faRulerTriangle";
import { faShare } from "@fortawesome/pro-light-svg-icons/faShare";
import { faShelves } from "@fortawesome/pro-light-svg-icons/faShelves";
import { faSignsPost } from "@fortawesome/pro-light-svg-icons/faSignsPost";
import { faSliders } from "@fortawesome/pro-light-svg-icons/faSliders";
import { faSplit } from "@fortawesome/pro-light-svg-icons/faSplit";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons/faTrashCan";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons/faTriangleExclamation";
import { faTriangleExclamation as faTriangleExclamationRegular } from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import { faTruck } from "@fortawesome/pro-light-svg-icons/faTruck";
import { faTruckArrowRight } from "@fortawesome/pro-light-svg-icons/faTruckArrowRight";
import { faTruckContainer } from "@fortawesome/pro-light-svg-icons/faTruckContainer";
import { faTruckFast } from "@fortawesome/pro-light-svg-icons/faTruckFast";
import { faUser } from "@fortawesome/pro-light-svg-icons/faUser";
import { faUsers } from "@fortawesome/pro-light-svg-icons/faUsers";
import { faWandMagicSparkles } from "@fortawesome/pro-light-svg-icons/faWandMagicSparkles";
import { faWandMagicSparkles as faWandMagicSparklesSolid } from "@fortawesome/pro-solid-svg-icons/faWandMagicSparkles";
import { faWarehouse } from "@fortawesome/pro-light-svg-icons/faWarehouse";
import { faWarehouseFull } from "@fortawesome/pro-light-svg-icons/faWarehouseFull";
import { faWrench } from "@fortawesome/pro-light-svg-icons/faWrench";
import { faXmark } from "@fortawesome/pro-light-svg-icons/faXmark";

import { initIconLibrary } from "@stordco/fe-components";

export const initIcons = () =>
  initIconLibrary(
    faAngleDown,
    faAngleDownLight,
    faAngleRight,
    faAnglesUpRegular,
    faArrowDownToLine,
    faArrowsRotate,
    faArrowUpArrowDown,
    faArrowUpFromLine,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faAsterisk,
    faBan,
    faBoltRegular,
    faBoltSlash,
    faBoltSolid,
    faBooks,
    faBoxCheck,
    faBoxesStacked,
    faBuilding,
    faBuildingCircleExclamation,
    faBuildingLight,
    faBuildings,
    faBullseyePointer,
    faCalendarCircleExclamation,
    faCalendarXmarkRegular,
    faCaretDown,
    faCartFlatbedEmpty,
    faChartNetwork,
    faChartPie,
    faChartPieSimple,
    faCheckRegular,
    faChevronDown,
    faCircle,
    faCircleCheck,
    faCircleInfo,
    faCircleInfo,
    faCircleLight,
    faCirclePlus,
    faCircleX,
    faCircleXmark,
    faCircleXRegular,
    faClipboardList,
    faClockRegular,
    faClockRotateLeft,
    faCloudArrowUp,
    faColon,
    faDiagramVenn,
    faDiamondExclamation,
    faDownload,
    faEllipsisVerticalRegular,
    faEllipsisVerticalSolid,
    faEnvelope,
    faEqualsRegular,
    faFileCircleXmark,
    faFileImport,
    faFolderGear,
    faGaugeCirclePlus,
    faGear,
    faGearsSolid,
    faHandHoldingBox,
    faHouse,
    faLightCircleInfo,
    faLinkHorizontal,
    faListTree,
    faLocationCheck,
    faLocationPinLock,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassChart,
    faMessagesQuestion,
    faParachuteBox,
    faPen,
    faPencil,
    faPenToSquare,
    faPersonDollyEmpty,
    faPhone,
    faPlusCircle,
    faPlusLight,
    faPlusRegular,
    faPuzzle,
    faPuzzlePiece,
    faQuestionCircle,
    faReceipt,
    faRefresh,
    faRoute,
    faRulerTriangle,
    faShare,
    faShelves,
    faSignsPost,
    faSliders,
    faSolidLock,
    faSplit,
    faTimes,
    faTrash,
    faTrashCan,
    faTriangleExclamation,
    faTriangleExclamation,
    faTriangleExclamationRegular,
    faTruck,
    faTruckArrowRight,
    faTruckContainer,
    faTruckFast,
    faUser,
    faUsers,
    faWandMagicSparkles,
    faWandMagicSparkles,
    faWandMagicSparklesSolid,
    faWarehouse,
    faWarehouseFull,
    faWrench,
    faXmark
  );
